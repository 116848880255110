import { Environment } from "./evironment.interface";

export const environment: Environment = {
  VERSION: "1.343.0",
  production: true,
  environment: 'production',
  afterAuthPath: '/dashboard',
  firebase: {
    apiKey: "AIzaSyBOf9T-SFn9Naz37KfA9tu_1vSDRz7MFUA",
    authDomain: "core-6p22-ukprod-658c405b.firebaseapp.com",
    projectId: "core-6p22-ukprod-658c405b",
    storageBucket: "core-6p22-ukprod-658c405b.appspot.com",
    messagingSenderId: "262565461276",
    appId: "1:262565461276:web:50ef141aa2d08168154ed9",
    measurementId: "G-YCK884EMM4"
  },
  braze: {
    apiKey: "262fb5c6-0b26-4086-94de-652309305da2",
    baseUrl: "sdk.fra-02.braze.eu"
  },
  stripe: "pk_live_4uixpExroXw0z9euXXsNOM0u",
  formIoRootUrl: "https://form-io.joiipetcare.com",
  tokboxAPIKey: "46402962",
  defaulttenant: "joii",
  providerswitcher: true,
  tenants: [{
    name: "Joii",
    icon: "https://play-lh.googleusercontent.com/WFYFOAJIGZmTSJVegziOu-dw6UVoAV7ldKMBwfZCfV2t-38dRGtUJZslivOljVDdWg",
    image: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FJoii_image.png?alt=media&token=35695f73-969f-4e4f-a285-4028e215ea72",
    pageimage: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FJoii_pageimage.png?alt=media&token=dfff150f-cf13-4d6b-b1b0-2aab7ab18c45",
    logo: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FJoii_logo.svg?alt=media&token=9046e258-a5ca-49a3-a22c-5fb78e920d15",
    friendly_id: "joii",
    subtitle: "Pay as you go",
    id: "joii-j4clq",
    emailandpassword: true,
    google: false,
    insurer: false
  },
  {
    name: "Asda",
    icon: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FASDA_icon.png?alt=media&token=960ed7ad-5e44-4b71-9c32-00e5e085eb9c",
    image: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Fasdaimage.png?alt=media&token=785e982f-79d3-4714-a993-b206041cc969",
    pageimage: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FASDA_pageimage.png?alt=media&token=dd69ab56-7a33-43e5-ab4b-159640033409",
    logo: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FASDA_money_logo.png?alt=media&token=97ce1611-f5df-4ef3-b102-531ed72b2a1e",
    friendly_id: "asda",
    subtitle: "Asda Pet Insurance",
    id: "asda-2vmg9",
    emailandpassword: true,
    google: false,
    insurer: true
  },
  {
    name: "Purely Pets",
    icon: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FPurelyPets_icon.png?alt=media&token=0f5a8133-d362-49e5-a5a0-14585ad33e36",
    image: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Fdevopsdog.jpeg?alt=media&token=a701a1f0-32fa-43f5-8fab-0ef723f1ef9a",
    pageimage: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FPurelyPets_pageimage.png?alt=media&token=2987a7eb-0153-4797-bb42-4c3cd8798c0d",
    logo: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FPurelyPets_logo.png?alt=media&token=4cd00734-7c45-4b0e-ac43-4327ee59e5f8",
    friendly_id: "purelypets",
    subtitle: "Purely Pets Pet Insurance",
    id: "purelypets-ooiu8",
    emailandpassword: true,
    google: false,
    insurer: true
  },
  {
    name: "Waggel",
    icon: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FWaggel_icon.png?alt=media&token=f68f5688-49dd-44af-851d-9fb4c470f725",
    image: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Fdevopsdog.jpeg?alt=media&token=a701a1f0-32fa-43f5-8fab-0ef723f1ef9a",
    pageimage: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FWaggel_pageimage.png?alt=media&token=ef1023f6-8c11-4b48-b966-03dc825c403d",
    logo: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FWaggel_logo.png?alt=media&token=b97bdf5c-9827-4456-bf36-90f0429ec4d9",
    friendly_id: "waggel",
    subtitle: "Waggel Pet Insurance",
    id: "waggel-50xx9",
    emailandpassword: true,
    google: false,
    insurer: true
  },
  {
    name: "Animal Friends",
    icon: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FAFI_icon.png?alt=media&token=87f113af-06d5-48c2-bc2c-7a98bf925afd",
    image: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Fdevopsdog.jpeg?alt=media&token=a701a1f0-32fa-43f5-8fab-0ef723f1ef9a",
    pageimage: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FAFI_pageimage.png?alt=media&token=1f96ef88-2ca7-4635-9f4e-a039d2711df5",
    logo: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FAFI_logo.png?alt=media&token=1b5aca3f-475a-4e2e-8dc3-704fc527128e",
    friendly_id: "animalfriends",
    subtitle: "Animal Friends Pet Insurance",
    id: "animalfriends-yyjn5",
    emailandpassword: true,
    google: false,
    insurer: true
  },
    // {
    //   name: "Mock",
    //   icon: "https://firebasestorage.googleapis.com/v0/b/core-6p22-sandbox-f7ca7d8d.appspot.com/o/tenants%2FDALL·E%202024-03-19%2009.27.05%20-%20Design%20a%20sophisticated%20and%20friendly%20logo%20for%20a%20mock%20insurer%20specializing%20in%20pet%20insurance.%20The%20logo%20should%20incorporate%20an%20abstract%2C%20stylish%20representa.webp?alt=media&token=ac51db3f-c943-46fc-87d6-45b3d9695c92",
    //   image: "https://firebasestorage.googleapis.com/v0/b/core-6p22-sandbox-f7ca7d8d.appspot.com/o/tenants%2FDALL·E%202024-03-19%2009.27.05%20-%20Design%20a%20sophisticated%20and%20friendly%20logo%20for%20a%20mock%20insurer%20specializing%20in%20pet%20insurance.%20The%20logo%20should%20incorporate%20an%20abstract%2C%20stylish%20representa.webp?alt=media&token=ac51db3f-c943-46fc-87d6-45b3d9695c92",
    //   pageimage: "https://firebasestorage.googleapis.com/v0/b/core-6p22-sandbox-f7ca7d8d.appspot.com/o/tenants%2F5abb04f4-8c68-4fc8-944f-bcce494e7669.webp?alt=media&token=a8849d91-7d59-43b9-bd16-6cd3fb49080c",
    //   logo: "https://firebasestorage.googleapis.com/v0/b/core-6p22-sandbox-f7ca7d8d.appspot.com/o/tenants%2FDALL·E%202024-03-19%2009.27.05%20-%20Design%20a%20sophisticated%20and%20friendly%20logo%20for%20a%20mock%20insurer%20specializing%20in%20pet%20insurance.%20The%20logo%20should%20incorporate%20an%20abstract%2C%20stylish%20representa.webp?alt=media&token=ac51db3f-c943-46fc-87d6-45b3d9695c92",
    //   friendly_id: "mockintegration",
    //   subtitle: "Mock Insurer",
    //   id: "mock-9049d",
    //   emailandpassword: true,
    //   google: false,
    //   insurer: true
    // }
  ],
  defaultregion: 'gb',
  regions: [{
    api: 'https://api.core.uk.joiipetcare.com',
    code: 'gb',
    flag: '🇬🇧',
    name: 'UK'
  },
  {
    api: 'https://api.core.de.joiipetcare.com',
    code: 'de',
    flag: '🇩🇪',
    name: 'Germany'
  }],
  vetCallCost: '£28',
  enableDarkmode: false,
  mixPanel_enabled: true,
  mixpanel_token: "7db80c1e2522cbf23690019ef160b30d",
  activate_trials: false,
  trial_algorithm_category_id: "35cb6e72-8204-4556-86e8-51cb1d230423",
  pms_base_url: ""
};
